import * as React from "react";
import { Helmet } from "react-helmet";
import { useConfig } from "gatsby-theme-advanced";
import Layout from "../layouts";
import LighboxGallery from "../components/Art/LighboxGallery";
import {ABSTRACTS_SURREAL, STREET_FILES} from "../components/Art/Artworks";

const StreetFilesPage = (): JSX.Element => {
  const config = useConfig();

  return (
    <Layout>
      <div className="street-files-container">
        <Helmet title={`Street Files| ${config.website.title}`} />
          <LighboxGallery artworks={STREET_FILES}></LighboxGallery>

      </div>
    </Layout>
  );
};

export default StreetFilesPage;
